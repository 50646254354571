import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import CopyToClipboard from "react-copy-to-clipboard";

import { Tooltip, Spin } from "antd";

import { LoadingOutlined } from "@ant-design/icons";
import { RiCalendarCheckFill, RiCloseCircleLine } from "react-icons/ri";
import { BsThreeDots } from "react-icons/bs";
import { MdAdd } from "react-icons/md";

import { useDisclosure } from "hooks/useDisclosure";

import { EventOrganizer, EventTypeOrganizer } from "types";
import { Colors } from "constants/colors";

import hasCookiesEnabled from "utils/hasCookiesEnabled";
import { checkUserPlatform } from "utils/plaftformDetect";

import {
  Container,
  ContainerItems,
  HeaderSearching,
  ContainerList,
  ListCards,
  CardEvent,
  ContainerEmptyEvent,
  ButtonEmptyEvent,
  HeaderEventBar,
  ListCardsMobile,
  Center,
  CenterHasMore,
  Box,
  DropDownEvent,
  ModalStyled,
  ContainerModalShare,
  ContainerIcons,
  IconSocial,
  ButtonBack,
  ContainerButtonBack,
  ContainerDivInput,
  ContainerInput,
  HeaderEvent,
} from "./styles";

import { ListTable } from "components/ListTable";

import Popup from "containers/Popup";
import { ListBreadcrumb } from "components/ListBreadcrumb";
import { Footer } from "components/Footer";
import { Header } from "components/Header";
import { Pagination } from "components/Pagination";

import ri_pencil_line from "assets/ri_pencil-line.svg";
import bar_icon from "assets/bar-icon.svg";
import square_icon from "assets/square-icon.svg";
import bar_icon_active from "assets/bar-icon-active.svg";
import square_icon_active from "assets/square-icon-active.svg";
import eyes_event_icon from "assets/eyes-event-icon.svg";
import integrations_event_icon from "assets/integrations-event-icon.svg";
import share_event_icon from "assets/share-event-icon.svg";
import whatsappIcon from "assets/whatsappIcon.svg";
import twitterIcon from "assets/twitterIcon.svg";
import facebookIcon from "assets/facebookIcon.svg";
import mailIcon from "assets/mailIcon.svg";
import linkedinIcon from "assets/linkedinIcon.svg";
import copyIcon from "assets/copyIcon.svg";
import { useSearchMyEvent } from "hooks/useSearchMyEvent";
import { FRONTLINE_URL } from "constants/environmentVariables";
import EventBanner from "components/ListTable/EventBanner";
import LoadMoreItems from "components/LoadMoreItems";

const metaTags = {
  title: `Meus eventos - ${process.env.REACT_APP_EVENTX_META_TITLE_NAME}`,
  description: process.env.REACT_APP_EVENTX_META_DESCRIPTION,
  noIndex: true,
};

export default function MyEvent() {
  const [visible, setVisible] = useState(false);
  const [userPlatform, setUserPlatform] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [activeTypeCard, setActiveTypeCard] = useState(0);
  const [eventSelected, setEventSelected] = useState<EventOrganizer | null>();

  const handleCookies = () => {
    setVisible(false);
  };

  useEffect(() => {
    !hasCookiesEnabled() && setVisible(true);
    setUserPlatform(checkUserPlatform());
  }, [setVisible, userPlatform]);

  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const {
    data,
    page,
    hasMoreItems,
    handleLoadMoreItemsMobile,
    loadingMoreItems,
    onChange,
  } = useSearchMyEvent();

  const { handleOk, isModalVisible, handleCancel, showModal } = useDisclosure();

  const handleOpenDropCardEvent = (event: EventOrganizer) => {
    if (event._id === eventSelected?._id) {
      setEventSelected(null);

      return;
    }

    setEventSelected(event);
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 38, color: Colors.primary100 }} spin />
  );

  return (
    <>
      <Header metaTags={metaTags} />

      <Container>
        <ContainerItems>
          <>
            <div className="bread">
              <ListBreadcrumb
                data={[
                  { name: "Home", link: "/" },
                  { name: "Meus eventos", link: "/" },
                ]}
              />
            </div>

            <HeaderEvent>
              <h1>Meus Eventos</h1>

              <div className="buttons">
                <Link to={`/my-event/new`}>
                  <button>
                    <MdAdd size={22} color={Colors.primary100} />

                    <span>Adicionar novo</span>
                  </button>
                </Link>
              </div>
            </HeaderEvent>
          </>
          <HeaderSearching>
            <HeaderEventBar>
              <div className="buttons">
                <button
                  onClick={() => setActiveTab(0)}
                  className={activeTab === 0 ? "first active" : "first"}
                >
                  Todos
                </button>
              </div>
            </HeaderEventBar>

            <div className="icons">
              {activeTypeCard === 0 ? (
                <img
                  className="active"
                  src={bar_icon_active}
                  alt="Logo"
                  width={25}
                  height={22.5}
                  onClick={() => setActiveTypeCard(0)}
                />
              ) : (
                <img
                  className="active"
                  src={bar_icon}
                  alt="Logo"
                  width={25}
                  height={22.5}
                  onClick={() => setActiveTypeCard(0)}
                />
              )}

              {activeTypeCard === 1 ? (
                <img
                  className="active"
                  src={square_icon_active}
                  alt="Logo"
                  width={25}
                  height={22.5}
                  onClick={() => setActiveTypeCard(1)}
                />
              ) : (
                <img
                  className="active"
                  src={square_icon}
                  alt="Logo"
                  width={25}
                  height={22.5}
                  onClick={() => setActiveTypeCard(1)}
                />
              )}
            </div>
          </HeaderSearching>

          {activeTab === 0 ? (
            <ContainerList>
              <div className="header-filter-mobile">
                <h1>Todos os eventos</h1>
              </div>

              {activeTypeCard === 1 ? (
                <ListCards>
                  {!data ? (
                    <Center>
                      <Spin spinning indicator={antIcon} />
                    </Center>
                  ) : null}

                  {data?.results?.map((event) => (
                    <CardEvent key={event._id}>
                      <div className="header-event">
                        <Link to={`/my-event/${event?.nickname}`}>
                          <h2>{event?.name}</h2>
                        </Link>

                        <div className="div-icon-three">
                          <Box
                            className="box"
                            active={event?.isOpen}
                            onClick={() => handleOpenDropCardEvent(event)}
                          >
                            <BsThreeDots
                              style={{ cursor: "pointer" }}
                              size={18}
                              color={Colors.black100}
                            />
                          </Box>

                          {eventSelected?._id === event._id ? (
                            <DropDownEvent>
                              <ul>
                                <Link
                                  style={{ textDecoration: "none" }}
                                  to={`/my-event/${event.nickname}`}
                                >
                                  <li>
                                    <div className="circle-icon">
                                      <img src={eyes_event_icon} alt="eyes" />
                                    </div>
                                    <span>Visualizar evento</span>
                                  </li>
                                </Link>

                                <Link
                                  style={{ textDecoration: "none" }}
                                  to={`/my-event/${event?.nickname}/edit`}
                                >
                                  <li>
                                    <div className="circle-icon">
                                      <img src={ri_pencil_line} alt="eyes" />
                                    </div>
                                    <span>Editar evento</span>
                                  </li>
                                </Link>

                                <Link
                                  style={{ textDecoration: "none" }}
                                  to={`/${event.nickname}`}
                                >
                                  <li>
                                    <div className="circle-icon">
                                      <img src={eyes_event_icon} alt="eyes" />
                                    </div>
                                    <span>Visualizar Link do Checkout</span>
                                  </li>
                                </Link>

                                <Link
                                  style={{ textDecoration: "none" }}
                                  to={`/my-event/${event.nickname}/integration`}
                                >
                                  <li>
                                    <div className="circle-icon">
                                      <img
                                        src={integrations_event_icon}
                                        alt="edit"
                                      />
                                    </div>
                                    <span>Gerenciar integrações</span>
                                  </li>
                                </Link>

                                <li className="last" onClick={showModal}>
                                  <div className="circle-icon">
                                    <img
                                      src={share_event_icon}
                                      alt="duplicate"
                                    />
                                  </div>
                                  <span>Compartilhar evento</span>
                                </li>
                              </ul>
                            </DropDownEvent>
                          ) : null}
                        </div>
                      </div>

                      <Link to={`/my-event/${event?.nickname}`}>
                        {event?.thumb ? (
                          <img
                            className="img-thumb"
                            src={event?.thumb}
                            alt="thumb"
                          />
                        ) : null}

                        {!event?.thumb ? <div className="thumb" /> : null}
                      </Link>
                    </CardEvent>
                  ))}
                </ListCards>
              ) : null}

              {activeTypeCard === 0 ? (
                <>
                  {!data ? (
                    <Center>
                      <Spin spinning indicator={antIcon} />
                    </Center>
                  ) : null}

                  <ListTable
                    events={data?.results}
                    handleOpenDropCardEvent={handleOpenDropCardEvent}
                    showModalShare={showModal}
                    eventSelected={eventSelected ?? null}
                  />
                </>
              ) : null}

              <ListCardsMobile>
                {!data ? (
                  <Center>
                    <Spin spinning indicator={antIcon} />
                  </Center>
                ) : null}

                {data?.results?.map((event) => (
                  <CardEvent key={event._id}>
                    <div className="header-event">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={`/my-event/${event.nickname}`}
                      >
                        <h2>{event?.name}</h2>
                      </Link>

                      <div className="div-icon-three">
                        <Box
                          active={event?.isOpen}
                          onClick={() => handleOpenDropCardEvent(event)}
                        >
                          <BsThreeDots
                            style={{ cursor: "pointer" }}
                            size={28}
                            color={Colors.black100}
                          />
                        </Box>

                        {eventSelected?._id === event._id ? (
                          <DropDownEvent>
                            <ul>
                              <Link
                                style={{ textDecoration: "none" }}
                                to={`/my-event/${event?._id}`}
                              >
                                <li>
                                  <div className="circle-icon">
                                    <img src={eyes_event_icon} alt="eyes" />
                                  </div>
                                  <span>Visualizar evento</span>
                                </li>
                              </Link>

                              <Link
                                style={{ textDecoration: "none" }}
                                to={`/my-event/${event?._id}/edit`}
                              >
                                <li>
                                  <div className="circle-icon">
                                    <img src={ri_pencil_line} alt="eyes" />
                                  </div>
                                  <span>Editar evento</span>
                                </li>
                              </Link>

                              <Link
                                style={{ textDecoration: "none" }}
                                to={`/${event.nickname}`}
                              >
                                <li>
                                  <div className="circle-icon">
                                    <img src={eyes_event_icon} alt="eyes" />
                                  </div>
                                  <span>Visualizar Link do Checkout</span>
                                </li>
                              </Link>

                              <Link
                                style={{ textDecoration: "none" }}
                                to={`/my-event/${event.nickname}/integration`}
                              >
                                <li>
                                  <div className="circle-icon">
                                    <img
                                      src={integrations_event_icon}
                                      alt="edit"
                                    />
                                  </div>
                                  <span>Gerenciar integrações</span>
                                </li>
                              </Link>

                              <li className="last" onClick={showModal}>
                                <div className="circle-icon">
                                  <img src={share_event_icon} alt="duplicate" />
                                </div>
                                <span>Compartilhar evento</span>
                              </li>
                            </ul>
                          </DropDownEvent>
                        ) : null}
                      </div>
                    </div>

                    <Link
                      style={{
                        textDecoration: "none",
                        width: "100%",
                        height: "100% ",
                      }}
                      to={`/my-event/${event.nickname}`}
                    >
                      <EventBanner
                        img={event.photo ?? "/assets/img-my-events.jpg"}
                      ></EventBanner>
                    </Link>
                  </CardEvent>
                ))}

                {loadingMoreItems ? (
                  <CenterHasMore>
                    <Spin spinning indicator={antIcon} />
                  </CenterHasMore>
                ) : null}
              </ListCardsMobile>

              <div className="pagination">
                <Pagination
                  totalCountOfRegisters={data?.count}
                  currentPage={Number(page)}
                  onPageChange={onChange}
                  // last_page={data?.meta.last_page}
                  registersPerPage={40}
                />
              </div>

              <LoadMoreItems
                handleLoadMore={handleLoadMoreItemsMobile}
                fetching={loadingMoreItems}
                hasMoreItems={hasMoreItems}
                length={data?.results?.length}
                total={data?.count}
              />
            </ContainerList>
          ) : null}

          {activeTab === 1 ? (
            <>
              <ContainerEmptyEvent>
                <h2>Não há nada por aqui :(</h2>
                <p>
                  Não há eventos disponíveis no momento. Mas você pode encontrar
                  outros eventos de seu interesse abaixo:
                </p>

                <ButtonEmptyEvent>
                  <RiCalendarCheckFill size={22} color={Colors.primary100} />
                  <span>Ir para a página de eventos</span>
                </ButtonEmptyEvent>
              </ContainerEmptyEvent>
            </>
          ) : null}
        </ContainerItems>

        <ModalStyled
          width={807}
          visible={isModalVisible}
          onOk={handleOk}
          style={{ borderRadius: "20px" }}
          onCancel={handleCancel}
          closeIcon={
            <RiCloseCircleLine
              style={{ marginTop: "14px", marginRight: "34px" }}
              size={43}
              color={Colors.black40}
            />
          }
          footer={null}
        >
          <ContainerModalShare>
            <h1 className="title">Compartilhar evento</h1>

            <p className="description">
              Selecione uma rede social para compartilhar o evento ou copie o
              link abaixo e divulgue onde desejar.
            </p>

            <ContainerIcons>
              <IconSocial
                href={`https://wa.me/?text=${window.location.protocol}${window.location.host}/${eventSelected?.nickname}%20Achei%20esse%20evento%20aqui%20muito%20daora!%20Todo%20mundo%20deveria%20vir%20comigo%20nessa%20%F0%9F%98%89!`}
                target="_blank"
                background={Colors.bgWhatsapp}
              >
                <img src={whatsappIcon} alt="" />
              </IconSocial>

              <IconSocial
                href={`https://twitter.com/intent/tweet?url=${window.location.protocol}${window.location.host}/${eventSelected?.nickname}&text=Achei%20esse%20evento%20aqui%20muito%20daora!%20Todo%20mundo%20deveria%20vir%20comigo%20nessa%20%F0%9F%98%89!`}
                target="_blank"
                background={Colors.bgTwitter}
              >
                <img src={twitterIcon} alt="" />
              </IconSocial>

              <IconSocial
                href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.protocol}${window.location.host}/${eventSelected?.nickname}`}
                target="_blank"
                background={Colors.bgFacebook}
              >
                <img src={facebookIcon} alt="" />
              </IconSocial>

              <IconSocial
                href={`mailto:info@example.com?&subject=&cc=&bcc=&body=${window.location.protocol}${window.location.host}/${eventSelected?.nickname}%0AAchei%20esse%20evento%20aqui%20muito%20daora!%20Todo%20mundo%20deveria%20vir%20comigo%20nessa%20%F0%9F%98%89!`}
                target="_blank"
                background={Colors.bgEmail}
              >
                <img src={mailIcon} alt="" />
              </IconSocial>

              <IconSocial
                href={`https://www.linkedin.com/shareArticle?mini=true&url=${window.location.protocol}${window.location.host}/${eventSelected?.nickname}&title=Achei%20esse%20evento%20aqui%20muito%20daora!%20Todo%20mundo%20deveria%20vir%20comigo%20nessa%20%F0%9F%98%89!`}
                target="_blank"
                background={Colors.bgLinkedin}
              >
                <img src={linkedinIcon} alt="" />
              </IconSocial>
            </ContainerIcons>

            <ContainerDivInput>
              <h2>Link do evento</h2>

              <ContainerInput>
                <input
                  readOnly
                  type="text"
                  placeholder={`${FRONTLINE_URL}/${eventSelected?.nickname}`}
                />

                <CopyToClipboard
                  text={`${window.location.protocol}${window.location.host}/${eventSelected?.nickname}`}
                  onCopy={() => {
                    setIsLinkCopied(true);
                    setTimeout(() => {
                      setIsLinkCopied(false);
                    }, 5000);
                  }}
                >
                  <Tooltip
                    placement="topLeft"
                    title={
                      isLinkCopied
                        ? "Link copiado para a área de transferência!"
                        : "Copiar link"
                    }
                  >
                    <button>
                      <img src={copyIcon} alt="" />
                    </button>
                  </Tooltip>
                </CopyToClipboard>
              </ContainerInput>
            </ContainerDivInput>

            <ContainerButtonBack>
              <ButtonBack onClick={handleOk}>
                <span>Voltar</span>
              </ButtonBack>
            </ContainerButtonBack>
          </ContainerModalShare>
        </ModalStyled>
      </Container>

      <Footer />

      {visible && <Popup handleVisible={handleCookies} />}
    </>
  );
}
