import { Modal, Select } from "antd";
import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

export const HeaderEvent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2.53rem;

  @media (max-width: 1024px) {
    margin-top: 25px;
    margin-bottom: 30px;
  }

  h1 {
    font-family: "Cairo";
    font-weight: 900;
    font-size: 1.87rem;
    margin-bottom: 0px;
    color: ${Colors.black100};

    @media (max-width: 1024px) {
      font-weight: bold;
      font-size: 16px;
      line-height: 25px;

      color: ${Colors.black100};
    }
  }

  div.buttons {
    display: flex;
    flex-direction: row;
    align-items: center;

    /* @media (max-width: 1024px) {
      display: none;
    } */

    button {
      cursor: pointer;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      width: 12.81rem;
      height: 2.81rem;

      background: ${Colors.primary10};
      border-radius: 0.65rem;

      @media (max-width: 1024px) {
        width: 40px;
        height: 40px;
      }

      span {
        font-family: "Cairo";
        font-weight: bold;
        font-size: 0.93rem;
        margin-bottom: 0px;

        color: ${Colors.primary100};
        margin-left: 10px;

        @media (max-width: 1024px) {
          display: none;
        }
      }
    }

    button.second {
      margin-left: 12px;
    }
  }
`;

export const ContainerItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1280px;

  padding-right: 38px;
  padding-left: 38px;

  margin-top: 9.14rem;

  width: 100%;

  @media (max-width: 1024px) {
    margin-top: 70px;
    padding-left: 28px;
    padding-right: 28px;
  }

  div.main-header {
    @media (max-width: 900px) {
      display: none;
    }

    h1 {
      font-family: "Cairo";
      margin-top: 2.53rem;
      font-weight: 900;
      font-size: 1.87rem;
      margin-bottom: 0px;
      color: ${Colors.black100};
    }
  }

  div.header-filter-mobile {
    margin-top: 26px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (min-width: 1024px) {
      display: none;
    }

    h1 {
      font-weight: bold;
      font-size: 16px;
      line-height: 25px;

      color: ${Colors.black100};
    }

    div.box {
      width: 25.73px;
      height: 25.73px;
      border-radius: 50%;
      background: ${Colors.white20};

      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 7px;
    }
  }

  div.bread {
    @media (max-width: 1024px) {
      display: none;
    }
  }
`;

export const ContentMobile = styled.div`
  @media (min-width: 800px) {
    display: none;
  }

  margin-top: 12px;

  h2 {
    font-weight: bold;
    font-size: 14px;
    line-height: 26px;
    color: ${Colors.secondary100};
    text-align: center;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  }
`;

export const ContainerAllEventsMobile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  div.events-d {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;

    div {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;

      h3 {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: ${Colors.secondary70};
        margin-top: 6px;
        margin-left: 7px;
      }
    }

    h4 {
      font-weight: normal;
      font-size: 10px;
      line-height: 15px;
      color: ${Colors.secondary40};
    }
  }

  div.events-r {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 30px;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      h3 {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: ${Colors.secondary70};
        margin-top: 6px;
        margin-left: 7px;
      }
    }

    h4 {
      font-weight: normal;
      font-size: 10px;
      line-height: 15px;
      color: ${Colors.secondary40};
    }
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 1200px) {
    margin-top: 30px;
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  border: 0;
  width: 262px;
  height: 60px;
  margin-left: 10px;
  background: ${Colors.primary10};
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-weight: bold;
    font-size: 20px;
    line-height: 160%;
    color: ${Colors.primary100};
    margin-left: 12px;
  }
`;

export const AboutMobile = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 18px 20px;
  border: 1px solid ${Colors.border60};
  border-radius: 10px;

  h2 {
    margin-bottom: 14px;
    font-weight: 600;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.black100};
  }

  p + p {
    margin-top: 12px;
  }

  p {
    font-weight: normal;
    font-size: 12px;
    line-height: 160%;
    color: ${Colors.gray10};
  }
`;

export const CardEvent = styled.div`
  outline: 0;
  width: 24.98rem;
  height: 18.6rem;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;

  background: ${Colors.white};
  border: 1px solid ${Colors.border20};
  box-sizing: border-box;
  box-shadow: 0px 2px 6px rgba(18, 25, 34, 0.05);
  border-radius: 14px;

  padding: 1.17rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 800px) {
    width: 100%;
    height: 200px;
    padding: 12px;

    border: 1px solid #f0f0f0;
    box-shadow: none;
    border-radius: 8px;
    margin-bottom: 18px;
  }

  @media (max-width: 1200px) {
    width: 98%;
  }

  div.header-event {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;

    h2 {
      font-family: "Cairo";
      font-weight: 700;
      font-size: 1.21rem;
      margin-bottom: 0px;
      color: ${Colors.black70};
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    @media (max-width: 800px) {
      h2 {
        font-weight: bold;
        font-size: 12px;
        line-height: 22px;

        color: ${Colors.black100};
      }
    }
  }

  a {
    width: 100%;
  }

  img.img-thumb {
    width: 100%;
    height: 200px;
    border-radius: 0.93rem;
  }

  div.thumb {
    width: 100% !important;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${Colors.primary100};
    font-weight: 700;
    font-family: Cairo;
    object-fit: cover;
    border-radius: 0.93rem;
    background-color: ${Colors.primary10};
  }

  img.icon {
    border-radius: 0.93rem;
    width: 100%;
    height: 100%;
    max-height: 13.03rem;
    margin-top: 0.27rem;

    @media (max-width: 800px) {
      height: 128px;
    }
  }
`;

export const ContentCardEvent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  div.content {
    display: flex;
    flex-direction: column;

    h2 {
      font-weight: bold;
      font-size: 26px;
      line-height: 160%;
      color: ${Colors.black100};
      margin-bottom: -1px;

      @media (max-width: 800px) {
        font-size: 11px;
        line-height: 21px;
        margin-top: 5px;
        margin-bottom: -2px;
      }
    }

    div.date {
      display: flex;
      align-items: center;

      span {
        color: ${Colors.primary100};
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;

        @media (max-width: 800px) {
          font-size: 11px;
          line-height: 21px;
          font-weight: normal;
          font-size: 8px;
          line-height: 11px;
        }
      }

      div.city {
        margin-left: 28.5px;
        display: flex;
        flex-direction: row;
        align-items: center;

        @media (max-width: 800px) {
          display: none;
        }

        span {
          margin-left: 7px;
        }
      }
    }
  }

  div.badge {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 74px;
    height: 26px;
    background: ${Colors.black10};
    border-radius: 14px;
    margin-top: 20px;

    @media (max-width: 800px) {
      margin-top: 15px;
    }

    span {
      color: ${Colors.secondary100};
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;

      @media (max-width: 800px) {
        font-weight: 600;
        font-size: 8px;
        line-height: 11px;
      }
    }

    @media (max-width: 800px) {
      width: 52px;
      height: 14px;
    }
  }

  div.description {
    margin-top: 25px;
    display: flex;
    flex-direction: column;

    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 160%;
      color: ${Colors.black30};
    }

    strong {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: ${Colors.secondary100};
    }
  }
`;

export const ListCards = styled.div`
  width: 100%;
  margin-top: 1.31rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 0.45rem;
  row-gap: 1.5rem;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 950px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 800px) {
    display: none;
  }

  /* @media (max-width: 880px) {
    grid-template-columns: repeat(1, 1fr);
  } */
`;

export const ListCardsMobile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media (min-width: 900px) {
    display: none;
  }
`;

export const DivSelect = styled.div`
  width: 100%;
  padding-left: 28px;
  padding-right: 28px;
  margin-top: 31px;
`;

export const ContainerAboutMobile = styled.div`
  width: 100%;
  padding-left: 28px;
  padding-right: 28px;
`;

export const ContactMobile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: ${Colors.gray20};
    opacity: 0.75;
    margin-top: 34px;
  }

  div.cards {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    div.card {
      width: 140px;
      height: 75px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid ${Colors.border20};
      box-shadow: 0px 2px 10px rgba(108, 93, 211, 0.05);
      border-radius: 8px;

      h3 {
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: ${Colors.black100};
        margin-top: 10px;
      }
    }
  }
`;

export const ContainerList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 900px) {
    div.header-filter-mobile {
      display: none;
    }
  }

  div.pagination {
    width: 100%;
    @media (max-width: 900px) {
      display: none;
    }
  }
`;

export const ContainerListMobile = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 28px 10px 28px;
  margin-top: 26px;

  background: linear-gradient(
    180deg,
    ${Colors.black2} 89.75%,
    rgba(248, 248, 250, 0) 101.35%
  );

  h2.header {
    font-weight: bold;
    font-size: 9px;
    line-height: 12px;
    text-transform: uppercase;
    color: ${Colors.gray10};
    opacity: 0.75;
    margin-top: 12px;
  }
`;

export const CardEventMobile = styled.div`
  width: 100%;
  min-height: 113px;
  margin-top: 15px;
  background: ${Colors.white};
  border: 1px solid ${Colors.border20};
  box-sizing: border-box;
  box-shadow: 0px 5px 15px rgba(108, 93, 211, 0.15);
  border-radius: 5px;
  border-radius: 14px;
  padding: 5px 3px 9px 3px;
  display: flex;
  flex-direction: row;

  img.productive {
    border-radius: 14px;
    max-width: 157px;
  }
`;

export const ContentCardEventMobile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-left: 13px;

  div.head {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  div.content {
    display: flex;
    flex-direction: column;
    margin-left: 8px;

    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 21px;

      color: ${Colors.secondary100};

      @media (max-width: 800px) {
        font-size: 11px;
        line-height: 21px;
        margin-bottom: -2px;
      }
    }

    div.date {
      display: flex;
      align-items: center;

      span {
        color: ${Colors.primary100};
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;

        @media (max-width: 800px) {
          font-size: 11px;
          line-height: 21px;
          font-weight: normal;
          font-size: 8px;
          line-height: 11px;
        }
      }

      div.city {
        margin-left: 28.5px;
        display: flex;
        flex-direction: row;
        align-items: center;

        @media (max-width: 800px) {
          display: none;
        }

        span {
          margin-left: 7px;
        }
      }
    }
  }

  div.badge {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 74px;
    height: 26px;
    background: ${Colors.black10};
    border-radius: 14px;
    margin-top: 20px;

    @media (max-width: 800px) {
      margin-top: 15px;
    }

    span {
      color: ${Colors.secondary100};
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;

      @media (max-width: 800px) {
        font-weight: 600;
        font-size: 8px;
        line-height: 11px;
      }
    }

    @media (max-width: 800px) {
      width: 52px;
      height: 14px;
    }
  }

  div.description {
    margin-top: 18px;
    display: flex;
    flex-direction: column;

    span {
      font-weight: 600;
      font-size: 7px;
      line-height: 10px;
      color: ${Colors.gray};
    }

    strong {
      font-weight: 600;
      font-size: 8px;
      line-height: 11px;
      color: ${Colors.secondary100};
    }
  }
`;

export const HeaderSearching = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-top: 1.68rem;

  @media (max-width: 900px) {
    display: none;
  }

  div.icons {
    width: 6%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-right: 1.5rem;

    img {
      cursor: pointer;
    }

    @media (max-width: 1180px) {
      width: 10%;
      margin-right: 10px;
    }
  }

  width: 100%;
  height: 63px;
  background: ${Colors.white};
  border: 1px solid ${Colors.border20};
  box-sizing: border-box;
  border-radius: 14px;

  div.filters {
    width: 18%;
    height: 100%;
    display: flex;
    border-left: 1px solid ${Colors.border30};
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 31px 0px 40px;

    @media (max-width: 1180px) {
      width: 28%;
    }
  }
`;

export const HeaderEventBar = styled.div`
  width: 80%;
  display: flex;

  padding-left: 25px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  div.icons {
    width: 7%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    img {
      cursor: pointer;
    }

    @media (max-width: 1180px) {
      width: 10%;
      margin-right: 10px;
    }
  }

  div.buttons {
    button {
      cursor: pointer;
      background: transparent;
      border: 0;
      margin-left: 38px;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 34px;
      color: ${Colors.black30};
    }

    button.first {
      margin-left: 0px;
    }

    button.active {
      font-weight: bold;
      font-size: 18px;
      line-height: 34px;

      color: ${Colors.black100};
    }
  }
`;

export const ContainerEmptyEvent = styled.div`
  margin-top: 78px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    font-weight: bold;
    font-size: 26px;
    line-height: 160%;
    color: ${Colors.gray10};
    opacity: 0.75;
  }

  p {
    font-weight: normal;
    font-size: 22px;
    line-height: 160%;
    text-align: center;
    color: ${Colors.gray20};
    margin-top: 20px;
    text-align: center;
    width: 50%;
  }
`;

export const ContainerEmptyEventMobile = styled.div`
  padding-top: 45px;
  padding-bottom: 34px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: ${Colors.gray10};
    opacity: 0.75;
  }

  p {
    font-weight: normal;
    font-size: 12px;
    line-height: 160%;
    text-align: center;
    color: ${Colors.gray20};
  }
`;

export const ButtonEmptyEvent = styled.button`
  cursor: pointer;
  border: 0;
  width: 362px;
  height: 60px;
  background: ${Colors.primary10};
  border-radius: 14px;
  margin-top: 62px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  span {
    font-weight: bold;
    font-size: 20px;
    line-height: 160%;
    color: ${Colors.primary100};
    margin-left: 10px;
  }
`;

export const ButtonEmptyEventMobile = styled.button`
  cursor: pointer;
  border: 0;
  width: 100%;
  height: 38px;
  background: ${Colors.primary10};
  border-radius: 8px;
  margin-top: 40px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    color: ${Colors.primary100};
    margin-left: 10px;
  }
`;

export const HeaderSearchingMobile = styled.div`
  display: flex;
  flex-direction: column;

  nav {
    width: 100%;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    border-radius: 8px;
    border: 1px solid ${Colors.border};

    div.searchInput {
      width: 100%;
      height: 100%;

      input {
        height: 100%;
        width: 90%;
        border: none;
        outline: none;
        background-color: transparent;
        padding: 0px 2px 0px 28px;
        font-weight: normal;
        font-size: 10px;
        line-height: 22px;
        color: ${Colors.gray};
      }
    }

    div.search {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22%;
      height: 100%;
      border-left: 1px solid ${Colors.border};
    }
  }

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const ContainerNextEvents = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  padding-bottom: 30px;

  border-bottom: 1px solid ${Colors.secondary10};

  h1.next {
    font-weight: bold;
    font-size: 14px;
    line-height: 26px;

    color: ${Colors.black100};
    margin-bottom: 0px;
  }

  div.card-next {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 113px;

    background: ${Colors.white};
    padding: 5px;

    box-shadow: 0px 5px 20px rgba(108, 93, 211, 0.08);
    border-radius: 5px;
    margin-top: 15px;

    img {
      border-radius: 8px;
      min-width: 190px;
    }

    div.content {
      display: flex;
      flex-direction: column;
      margin-left: 16px;

      div.dots {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        /* margin-left: 10px; */
        margin-top: 24px;
      }

      h2 {
        font-weight: bold;
        font-size: 11px;
        line-height: 21px;
        margin-bottom: 0px;
        color: ${Colors.secondary100};
      }

      span.date {
        font-weight: normal;
        font-size: 8px;
        line-height: 11px;

        color: ${Colors.primary100};

        text-transform: uppercase;
      }

      div.badge {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 16px;
        width: 52px;
        height: 14px;

        background: ${Colors.secondary10};
        border-radius: 14px;

        h6.badge {
          font-weight: 600;
          font-size: 8px;
          line-height: 11px;
          margin-bottom: 0px;

          color: ${Colors.secondary100} #241058;
        }
      }
    }
  }
`;

export const DropDownEvent = styled.div`
  position: absolute;
  background-color: ${Colors.white10};
  width: 15.93rem;
  height: 199px;
  -webkit-tap-highlight-color: transparent;

  margin-top: 0.42rem;
  z-index: 95;

  padding-left: 0.56rem;
  padding-right: 0.56rem;

  margin-left: -218px;
  border: 1px solid ${Colors.border20};

  background-color: ${Colors.white};

  box-shadow: 0px 13px 40px rgba(0, 0, 0, .3);
  border-radius: 0.56rem;

  ul {
    position: relative;

    h2 {
      color: ${Colors.secondary20};
      font-weight: bold;
      font-size: 16px;
    }

    li {
      cursor: pointer;
      width: 100%;
      height: 2.43rem;
      list-style: none;

      border-bottom: 1px solid #e1e1e1;
      display: flex;
      flex-direction: row;

      align-items: center;
      margin-bottom: 0px;

      div.circle-icon {
        width: 1.5rem;
        height: 1.5rem;
        background: ${Colors.secondary10};
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 0.89rem;
          height: 0.75rem;
        }
      }

      div.circle-icon-remove {
        width: 1.5rem;
        height: 1.5rem;
        background: ${Colors.red0};
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 0.89rem;
          height: 0.75rem;
        }
      }

      a {
        color: ${Colors.black50};
        margin-left: 0.56rem;
      }

      span {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 0.75rem;
        margin-bottom: 0px;
        margin-left: 0.56rem;
        color: ${Colors.black50};
      }
    }

    li.last {
      border-bottom: 0px;
    }

    li.exit {
      border: 0;
      margin-bottom: 0px;

      span {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 0.75rem;
        margin-bottom: 0px;
        margin-left: 0.56rem;
        color: ${Colors.pink30};
      }
    }
  }
`;

export const AccountDropDownNextEvent = styled.div`
  /* display: block; */
  position: absolute;
  background-color: ${Colors.white10};
  width: 179px;
  height: 138px;

  z-index: 95;

  padding-left: 12px;
  padding-right: 12px;

  margin-left: -150px;
  margin-top: -170px;
  border: 1px solid ${Colors.border20};
  box-sizing: border-box;

  background-color: ${Colors.white};

  box-shadow: 0px 18px 33px rgba(108, 93, 211, 0.1);
  border-radius: 3px;

  ul {
    margin-top: 12px;
    position: relative;

    h2 {
      color: ${Colors.secondary20};
      font-weight: bold;
      font-size: 16px;
      line-height: 160%;
    }

    li {
      cursor: pointer;
      width: 100%;
      list-style: none;
      height: 28px;

      margin-top: 2px;
      border-bottom: 2px solid ${Colors.border10};
      display: flex;
      align-items: center;

      span {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 100%;

        color: ${Colors.black100};
      }
    }

    li.exit {
      border: 0;

      span {
        font-weight: normal;
        font-size: 12px;
        line-height: 100%;

        color: ${Colors.pink30};
      }
    }
  }
`;

export const Center = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CenterHasMore = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface BoxProps {
  active?: boolean;
}

export const Box = styled.div<BoxProps>`
  cursor: pointer;
  width: 2.43rem;
  height: 2.43rem;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${Colors.white};
  border: 1px solid
    ${(props) => (props.active ? Colors.primary100 : Colors.black10)};
  border-radius: 0.56rem;
`;

export const SelectStyled = styled(Select)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .ant-select-selection-item {
    font-family: "Poppins";
    font-size: 0.93rem;
    color: ${Colors.secondary40};
  }
`;

export const SelectStyledWithoutDrop = styled(Select)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  span.ant-transfer-list-header-dropdown {
    display: none;
  }

  .ant-select-selection-item {
    font-family: "Poppins";
    font-size: 0.93rem;
    color: ${Colors.secondary40};
  }
`;

export const HeaderFilters = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  h3.filter {
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;

    display: flex;
    align-items: center;

    color: ${Colors.secondary40};
    margin-bottom: 0px;
  }

  div.filters {
    width: 100%;
    display: flex;
    margin-top: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    .select-ant {
      font-family: "Poppins";
      width: 56%;
      margin-bottom: 10px;
    }

    @media (max-width: 800px) {
      flex-direction: column;
      justify-content: normal;
    }
  }
`;

export const DivIsSelected = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 290px;
  height: 42px;
  margin-bottom: 10px;

  background: ${Colors.primary100};
  border-radius: 8px;
  margin-right: 10px;
  padding: 0px 0.92rem 0px 0.92rem;

  @media (max-width: 800px) {
    width: 100%;
    height: 32px;
    padding: 0px 6px 0px 12px;
    border-radius: 6px;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  h3.selected {
    font-weight: bold;
    font-size: 0.93rem;
    line-height: 160%;
    font-family: "Poppins";

    color: ${Colors.white};
    margin-bottom: 0px;

    @media (max-width: 800px) {
      font-size: 12px;
      font-weight: normal;
    }
  }
`;

export const BorderSelect = styled.div`
  border: 2px solid ${Colors.secondary20};
  width: 290px;
  height: 2.81rem;
  border-radius: 0.37rem;
  margin-bottom: 0.45rem;
  margin-right: 0.51rem;
  display: flex;

  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 0.45rem;

    height: 32px;
    border-radius: 6px;
  }
`;

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
  }
`;

export const ContainerModalShare = styled.div`
  padding: 40px 56px 25px 56px;

  @media (max-width: 800px) {
    padding: 40px 16px 25px 16px;
  }

  h1.title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1.87rem;
    margin-bottom: 0px;
    color: ${Colors.secondary100};
  }

  p.description {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 1.03rem;
    margin-bottom: 0px;

    color: ${Colors.black70};
    margin-top: 0.93rem;
  }
`;

export const ContainerIcons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 2.15rem;
  padding: 0px 83px;

  @media (max-width: 800px) {
    padding: 0px 0px;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`;

interface IconSocialProps {
  background: string;
}

export const IconSocial = styled.a<IconSocialProps>`
  cursor: pointer;
  width: 3.93rem;
  height: 3.93rem;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 1.87rem;
    height: 1.87rem;
  }

  background-color: ${(props) => props.background ?? Colors.white};

  @media (max-width: 800px) {
    margin: 5px;
  }
`;

export const ContainerDivInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1.87rem;

  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 0.93rem;
    margin-bottom: 4px;

    display: flex;
    align-items: center;

    color: ${Colors.secondary40};
  }
`;

export const ContainerInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  input {
    outline: 0;
    width: 100%;
    height: 2.81rem;

    background: ${Colors.white};

    border: 2px solid ${Colors.secondary20};
    border-radius: 0.37rem;
    padding-left: 0.93rem;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 0.93rem;

    color: ${Colors.purple20};
  }

  button {
    outline: 0;
    border: 0;
    cursor: pointer;
    width: 4.68rem;
    height: 2.81rem;

    background: ${Colors.primary100};
    box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
    border-radius: 0.65rem;
    margin-left: 0.93rem;

    img {
      width: 1.12rem;
      height: 1.12rem;
    }
  }
`;

export const ContainerButtonBack = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3.84rem;
`;

export const ButtonBack = styled.div`
  cursor: pointer;
  width: 12.28rem;
  height: 2.81rem;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${Colors.primary10};
  border-radius: 0.65rem;

  span {
    font-family: "Cairo";
    font-style: normal;
    font-weight: 700;
    font-size: 0.93rem;
    margin-bottom: 0px;
    text-align: center;

    color: ${Colors.primary100};
  }
`;
