import { useNavigate } from "react-router-dom";

import { Select, Spin } from "antd";

import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { RiBankLine } from "react-icons/ri";
import { IoChevronDownOutline } from "react-icons/io5";

import { LoadingOutlined } from "@ant-design/icons";

import { Colors } from "constants/colors";

import {
  Card,
  ColumnContent,
  Container,
  Divider,
  DividerNav,
  Hr,
  NavHeader,
  RowtTitle,
  SubTitle,
  SubtitleNav,
  Title,
  TitleNav,
  BorderSelect,
  LabelSelect,
  ContainerSelect,
  StyledSelect,
  ButtonSelectBank,
} from "./styles";

import { ContainerButtons, ButtonPrev, ButtonNext } from "../../styles";

import { GridInputs } from "./styles";

import { InputForm } from "components/Form/InputForm";

import { CreateBankDataOrganizerFormData, type OrganizerResponse } from "types";

import { IoMdInformationCircle } from "react-icons/io";
import { useCreateOrganizer } from "contexts/CreateOrganizerContext";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { createOrganizerBankDataFormSchema } from "lib/validations/createOrganizer";

import Datas from "constants/Datas";

import { ModalSuccess } from "components/ModalSuccess";
import { useDisclosure } from "hooks/useDisclosure";
import { AddBank } from "services/Hook/Organizer/Bank";
import { errorNotification } from "components/Notification";
import { ModalSelectBank } from "components/ModalSelectBank";
import { ModalHelp } from "components/ModalHelp";
import { useAuthOrganizerById } from "services/Hook/Organizer";
import { useCallback, useEffect } from "react";

const { Option } = Select;

export function BankData() {
  const { organizer } = useCreateOrganizer();

  const { register, control, handleSubmit, formState, setValue, watch } =
    useForm<CreateBankDataOrganizerFormData>({
      resolver: yupResolver(createOrganizerBankDataFormSchema),
    });

  const { errors, isSubmitting } = formState;

  const { handleCancel, isModalVisible, showModal } = useDisclosure();

  const { data: dataOrganizer } = useAuthOrganizerById<OrganizerResponse>(String(organizer?.identification?.organizerId));

  const bankWatch = watch("bank");

  const {
    handleCancel: handleCancelBank,
    isModalVisible: isModalVisibleBank,
    showModal: showModalBank,
    handleOk: handleOkBank,
  } = useDisclosure();


  const {
    handleCancel: handleCancelHelp,
    isModalVisible: isModalVisibleHelp,
    showModal: showModalHelp,
  } = useDisclosure();


  const navigate = useNavigate();

  const handleSubmitBankData: SubmitHandler<
    CreateBankDataOrganizerFormData
  > = async (data) => {
    const { account, agency, bank, digit, type_account, holder_name } = data;

    const { identification } = organizer;

    if (identification !== null) {
      const credentials = {
        organizer_id: identification?.organizerId,
        holder_name,
        bank_id: String(bank),
        agency,
        account,
        digit,
        type_account: Number(type_account),
        default: true,
      };

      try {
        await AddBank({ credentials });

        showModal();
      } catch (err: any) {
        errorNotification(
          "Aconteceu um erro ao cadastrar seu banco.",
          JSON.stringify(err?.response?.data?.message ?? "Tente novamente.")
        );
      }
    }
  };

  const redirectToHome = useCallback(() => {
    organizer.resetTheStore();

    handleCancel();

    navigate("/my-event/new");
  },[handleCancel, navigate, organizer]);

  const redirectToProfile = () => {
    organizer.resetTheStore();

    handleCancelHelp();

    navigate(`/organizer/profile/organizer-data/${organizer?.identification?.organizerId}`);
  };

  const handleSelectBank = (bankId: string) => {
    setValue("bank", bankId);

    handleOkBank();
  };

  useEffect(() => {
    if(dataOrganizer && dataOrganizer?.bankAccounts?.length > 0){
      redirectToHome()
    }
  },[dataOrganizer, redirectToHome])

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: Colors.white }} spin />
  );

  const findBankById = bankWatch
    ? Datas.Banks.find((bank) => bank.value === bankWatch)
    : { value: "", label: "" };

  return (
    <Container onSubmit={handleSubmit(handleSubmitBankData)}>
      <Card>
        <Title>Dados bancários</Title>

        <SubTitle>
          A conta bancária selecionada deve pertencer a você. Não serão aceitas
          contas de terceiros.
        </SubTitle>

        <Divider />

        <NavHeader>
          <Hr />

          <ColumnContent>
            <RowtTitle>
              <IoMdInformationCircle color={Colors.primary100} size={28} />

              <TitleNav>Avisos</TitleNav>
            </RowtTitle>

            <DividerNav />

            <SubtitleNav>
              . A conta bancária deve ser vinculada ao CPF ou CNPJ cadastrado na
              Eventx.
            </SubtitleNav>
          </ColumnContent>
        </NavHeader>

        <ContainerSelect>
          <LabelSelect>Banco</LabelSelect>

          <ButtonSelectBank type="button" onClick={showModalBank}>
            {bankWatch ? (
              <span>{findBankById?.label}</span>
            ) : (
              <span>Selecione uma opção</span>
            )}

            <IoChevronDownOutline color={Colors.secondary30} />
          </ButtonSelectBank>

          {!!errors.bank ? (
            <span className="error">{errors?.bank?.message}</span>
          ) : null}
        </ContainerSelect>

        <InputForm
          isFull
          {...register("agency")}
          icon={<RiBankLine size={22} color={Colors.secondary40} />}
          error={errors.agency}
          label="Agência (sem dígito)"
        />

        <GridInputs>
          <InputForm
            isFull
            {...register("account")}
            icon={<RiBankLine size={22} color={Colors.secondary40} />}
            error={errors.account}
            label="Conta"
          />

          <InputForm
            isFull
            {...register("digit")}
            icon={<RiBankLine size={22} color={Colors.secondary40} />}
            error={errors.digit}
            label="Dígito"
          />
        </GridInputs>

        <InputForm
          isFull
          {...register("holder_name")}
          icon={<RiBankLine size={22} color={Colors.secondary40} />}
          error={errors.holder_name}
          label="Titular da conta"
        />

        <ContainerSelect>
          <LabelSelect>Tipo de conta</LabelSelect>

          <Controller
            control={control}
            name="type_account"
            rules={{ required: "Salutation is required" }}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <BorderSelect>
                <StyledSelect
                  size="large"
                  labelInValue
                  bordered={false}
                  placeholder="Selecione uma opção"
                  value={value}
                  style={{
                    width: "100%",
                  }}
                  onChange={(e: any) => {
                    onChange(e.value);
                  }}
                >
                  <Option value={"1"} key={"1"}>
                    Corrente
                  </Option>

                  <Option value={"2"} key={"2"}>
                    Poupança
                  </Option>
                </StyledSelect>
              </BorderSelect>
            )}
          />

          {!!errors.type_account ? (
            <span className="error">{errors?.type_account?.message}</span>
          ) : null}
        </ContainerSelect>
      </Card>

      <ContainerButtons>
        <ButtonPrev active type="button" onClick={showModalHelp}>
          <span>Pular</span>
        </ButtonPrev>

        <ButtonNext type="submit" isLoading={isSubmitting}>
          <Spin spinning={isSubmitting} indicator={antIcon} />

          <span>Prosseguir</span>
        </ButtonNext>
      </ContainerButtons>

      <ModalHelp
        title="Deseja pular esta etapa?"
        subtitle="Você pode pular esta etapa agora e já dar inicio as suas vendas, mas ela será necessária quando for solicitar saques."
        isModalVisible={isModalVisibleHelp}
        handleCancel={handleCancelHelp}
        handleOk={redirectToProfile}
        leftButtonTitle="preencher"
        rightButtonTitle="ok, pular"
      />

      <ModalSuccess
        handleCancel={() => redirectToHome()}
        handleOk={() => redirectToHome()}
        isModalVisible={isModalVisible}
        title="Cadastro de organizador finalizado!"
        subtitle="Clique em OK para prosseguir."
      />

      <ModalSelectBank
        isModalVisible={isModalVisibleBank}
        handleCancel={handleCancelBank}
        handleOk={handleOkBank}
        handleSelectBank={handleSelectBank}
      />
    </Container>
  );
}
