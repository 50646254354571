import { CreateAddAndEditTicket } from "components/CreateAndEditTicket";
import { DrawerOverlay } from "components/DrawerOverlay";
import { Colors } from "constants/colors";
import { useCreateAndEditTicket } from "contexts/CreateAndEditTicketContext";
import { BiChevronDown } from "react-icons/bi";

interface DrawerCreateAndEditTicketProps {
  event_id: string;
}

export function DrawerCreateAndEditTicket({
  event_id,
}: DrawerCreateAndEditTicketProps) {
  const { isOpenDrawerTicket, setIsOpenDrawerTicket } =
    useCreateAndEditTicket();

  return (
    <DrawerOverlay
      title="Criar ingresso"
      icon={
        <BiChevronDown
          color={Colors.white}
          size={24}
          onClick={() => setIsOpenDrawerTicket(false)}
        />
      }
      isVisible={isOpenDrawerTicket}
      handleOk={() => setIsOpenDrawerTicket(false)}
    >
      <CreateAddAndEditTicket event_id={event_id} />
    </DrawerOverlay>
  );
}
