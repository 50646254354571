import { useEffect, useState } from "react";
import { useLocation, Link, useParams } from "react-router-dom";

import { Tabs, Spin, Tooltip } from "antd";

import CopyToClipboard from "react-copy-to-clipboard";

import { LoadingOutlined } from "@ant-design/icons";
import { IoMdSettings } from "react-icons/io";
import { MdAdd, MdEdit } from "react-icons/md";
import { FiMapPin } from "react-icons/fi";
import { RiCalendar2Line, RiCloseCircleLine } from "react-icons/ri";

import { FaWhatsapp } from "react-icons/fa";
import { BiChevronRight, BiPlus } from "react-icons/bi";
import { AiOutlineMail } from "react-icons/ai";

import { useAuthMyEvent, useMyEventDetail } from "services/Hook/Event";

import {
  useAuthTicketsByEventsId,
  useTicketEvent,
} from "services/Hook/Event/Ticket";

import { Colors } from "constants/colors";
import Constants from "constants/Datas";
import { EAdminStatus, Event, TicketEvent } from "types";

import hasCookiesEnabled from "utils/hasCookiesEnabled";
import { checkUserPlatform } from "utils/plaftformDetect";

import {
  Container,
  ContainerItems,
  HeaderEvent,
  ContainerTabs,
  ContainerDetailEvent,
  ContainerInformations,
  ContainerDescription,
  ContainerCards,
  CardTicket,
  ContainerModal,
  ContainerAboutOrganizerMobile,
  ModalStyled,
  Center,
  ParentalRatingContainer,
  ParentalRatingIcon,
  MobileEditButton,
  MobileAddTicket,
  FooterStyled,
  ContainerLink,
  RowShared,
  ButtonShare,
  TitleLocalization,
  RowCardLocalization,
  StatusComponent,
} from "./styles";

import Popup from "containers/Popup";
import { Header } from "components/Header";
import { ListBreadcrumb } from "components/ListBreadcrumb";
import { EmptyTicketComponent } from "components/EmptyTicketComponent";

import selo_google_play from "assets/selo-google-play.jpg";
import selo_app_store from "assets/selo-app-store.jpg";
import lock_open from "assets/lock-open.svg";
import lock_closed from "assets/lock-closed.svg";
import copy_icon from "assets/copy-icon.svg";
import ticket_t from "assets/ticket-t.svg";
import billet from "assets/billet.svg";

import banner_event from "assets/banner-event.jpg";

import { ListTicketByEvent } from "components/ListTicketByEvent";
import { ModalCreateAndEditTicket } from "components/ModalCreateAndEditTicket";
import { DrawerCreateAndEditTicket } from "components/DrawerCreateAndEditTicket";
import { useCreateAndEditTicket } from "contexts/CreateAndEditTicketContext";
import { Footer } from "components/Footer";
import { useOrganizerById } from "services/Hook/Organizer";
import { StatusWrapper } from "components/ListTable/styles";
import { ADMIN_STATUS } from "constants/adminStatus";

const { TabPane } = Tabs;

export default function MyEventDetail() {
  const location = useLocation();

  const { setIsOpenModalTicket, setIsOpenDrawerTicket } =
    useCreateAndEditTicket();
  const [visible, setVisible] = useState(false);
  const [userPlatform, setUserPlatform] = useState("");
  const [, setIsModalVisible] = useState(false);
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const { id } = useParams();

  const { data } = useAuthMyEvent<Event>(id ?? "");

  const { data: dataTickets } = useAuthTicketsByEventsId([String(id)]);

  const { data: dataOrganizer } = useOrganizerById(String(data?.organizer));

  const [activeKey, setActiveKey] = useState("1");

  const handleActiveTab = (activeKey: string) => {
    setActiveKey(activeKey);
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 38, color: Colors.primary100 }} spin />
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleCookies = () => {
    setVisible(false);
  };

  useEffect(() => {
    !hasCookiesEnabled() && setVisible(true);
    setUserPlatform(checkUserPlatform());
  }, [setVisible, userPlatform]);

  const metaTags = {
    title: `${data?.name ?? ""} - ${
      process.env.REACT_APP_EVENTX_META_TITLE_NAME
    }`,
    noIndex: true,
  };

  return (
    <>
      <Header metaTags={metaTags} />

      <Container>
        <ContainerItems>
          <div className="bread">
            <ListBreadcrumb
              data={[
                { name: "Home", link: "/" },
                { name: "Meus eventos", link: "/my-event" },
                { name: data?.name ?? "", link: "/" },
              ]}
            />
          </div>
          <HeaderEvent>
            <h1>Visualizar evento</h1>

            {activeKey === "1" ? (
              <div className="buttons">
                <Link to={`/my-event/${data?._id}/edit`}>
                  <button>
                    <MdEdit size={22} color={Colors.primary100} />
                    <span>Editar evento</span>
                  </button>
                </Link>

                <Link to={`/my-event/${data?._id}/integration`}>
                  <button className="second">
                    <IoMdSettings size={22} color={Colors.primary100} />

                    <span>Gerenciar integrações</span>
                  </button>
                </Link>
              </div>
            ) : null}

            {activeKey === "2" ? (
              <div className="buttons">
                <button onClick={() => setIsOpenModalTicket(true)}>
                  <MdAdd size={22} color={Colors.primary100} />

                  <span>Adicionar novo</span>
                </button>
              </div>
            ) : null}
          </HeaderEvent>

          <ContainerTabs>
            <Tabs onChange={handleActiveTab} activeKey={activeKey}>
              <TabPane tab="Detalhes do evento" key="1">
                {!data ? (
                  <Center>
                    <Spin spinning indicator={antIcon} />
                  </Center>
                ) : null}

                {data ? (
                  <ContainerDetailEvent>
                    {data?.admin_status ? (
                      <StatusWrapper>
                        <h2 className="resume">STATUS DO ADMINISTRADOR</h2>
                        <StatusComponent status={data?.admin_status}>
                          <span>
                            {ADMIN_STATUS.find(
                              (a) =>
                                a.value === data?.admin_status?.toLowerCase()
                            )?.label?.toUpperCase()}
                          </span>
                        </StatusComponent>

                        {data?.admin_status_detail &&
                        (data.admin_status === EAdminStatus.REQUIRED_CHANGES ||
                          data.admin_status === EAdminStatus.REJECTED) ? (
                          <span>Motivo: {data.admin_status_detail}</span>
                        ) : null}
                      </StatusWrapper>
                    ) : null}

                    <h2 className="resume">RESUMO DO EVENTO</h2>

                    <div className="container-img">
                      <img src={data?.photo ?? banner_event} alt="Logo" />
                    </div>

                    <div className="event-privacity">
                      {data?.privacity === "1" ? (
                        <>
                          <img src={lock_open} alt="open" />
                          <h3>EVENTO PÚBLICO</h3>
                        </>
                      ) : (
                        <>
                          <img src={lock_closed} alt="open" />
                          <h3>EVENTO PRIVADO</h3>
                        </>
                      )}
                    </div>

                    <ContainerInformations>
                      <h2>{data?.name}</h2>

                      <div className="publicate">
                        <div className="status"></div>
                        <h3>Publicado</h3>
                      </div>

                      <div className="local">
                        <FiMapPin size={18} color={Colors.primary100} />
                        <h4>
                          {data?.address_name ? `${data?.address_name} |` : ""}{" "}
                          {data?.address_street}, {1705} -{" "}
                          {data?.address_district}, {data?.address_city} -{" "}
                          {data?.address_state}, {data?.address_zipcode}
                        </h4>
                      </div>

                      <div className="data">
                        <RiCalendar2Line size={18} color={Colors.primary100} />

                        <h4>
                          {data.formattedDate}, {data.formattedHour}
                        </h4>
                      </div>

                      <div className="link-event">
                        <h4 className="link">Link do evento</h4>

                        <nav>
                          <div className="searchInput">
                            <input
                              placeholder={`${process.env.REACT_APP_EVENTX_MICROFRONT}/${data?.nickname}`}
                            />
                          </div>

                          <div className="search">
                            <CopyToClipboard
                              text={`${process.env.REACT_APP_EVENTX_MICROFRONT}/${data?.nickname}`}
                              onCopy={() => {
                                setIsLinkCopied(true);
                                setTimeout(() => {
                                  setIsLinkCopied(false);
                                }, 5000);
                              }}
                            >
                              <Tooltip
                                placement="topLeft"
                                title={
                                  isLinkCopied
                                    ? "Link copiado para a área de transferência!"
                                    : "Copiar link"
                                }
                              >
                                <div className="copy">
                                  <img
                                    src={copy_icon}
                                    alt=""
                                    width={10}
                                    height={10}
                                  />
                                </div>
                              </Tooltip>
                            </CopyToClipboard>
                          </div>
                        </nav>
                      </div>
                    </ContainerInformations>

                    <ContainerDescription>
                      <div className="title-description">
                        <h2>Descrição</h2>

                        <span>{data?.description}</span>
                      </div>

                      <div className="classification">
                        <h4>Classificação indicativa</h4>

                        <ParentalRatingContainer>
                          <ParentalRatingIcon
                            rating={Constants.ParentalRating[data?.age]}
                          >
                            <p className="inside">
                              {Constants.ParentalRating[data?.age]?.age}
                            </p>
                          </ParentalRatingIcon>
                          {data?.age && data?.age !== 1 ? (
                            <p className="normal">
                              O evento é restrito para maiores de{" "}
                              {Constants.ParentalRating[data?.age]?.age} anos de
                              idade.
                            </p>
                          ) : null}

                          {data?.age && data?.age === 1 ? (
                            <p className="normal">
                              L - Livre para todas as idades.
                            </p>
                          ) : null}
                        </ParentalRatingContainer>
                      </div>

                      <div className="organizer">
                        <h2 className="title-organizer">Organizador</h2>

                        <div className="border">
                          <div className="header">
                            <div className="avatar"></div>

                            <div className="content">
                              <h4>{dataOrganizer?.name}</h4>

                              <div className="informations-content">
                                <div className="row">
                                  <div className="square">
                                    <FaWhatsapp
                                      size={14}
                                      color={Colors.primary100}
                                    />
                                  </div>

                                  <h5>{dataOrganizer?.business_phone}</h5>
                                </div>

                                <div className="row-left">
                                  <div className="square">
                                    <AiOutlineMail
                                      size={14}
                                      color={Colors.primary100}
                                    />
                                  </div>
                                  <h5>{dataOrganizer?.business_email}</h5>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="informations">
                            <h5>Sobre o organizador</h5>

                            <span>{dataOrganizer?.description}</span>
                          </div>
                        </div>
                      </div>
                    </ContainerDescription>

                    <ContainerAboutOrganizerMobile>
                      <h3 className="organizer">Sobre o organizador</h3>

                      <span className="organizer">
                        {data?.organizer?.description}
                      </span>
                    </ContainerAboutOrganizerMobile>

                    <MobileEditButton to={`/my-event/${data?.nickname}/edit`}>
                      Editar evento
                    </MobileEditButton>
                  </ContainerDetailEvent>
                ) : null}

                <FooterStyled>
                  <h1>OUTRAS OPÇÕES</h1>

                  <Link className="link" to="/ticket-office">
                    <div className="drop">
                      <div className="first">
                        <div className="badge">
                          <img
                            className="ticket"
                            src={ticket_t}
                            alt="badge"
                            width={20}
                            height={21}
                          />
                        </div>
                        <h2>Bilheteria</h2>
                      </div>

                      <BiChevronRight
                        className="icon"
                        color={Colors.primary100}
                        size={28}
                      />
                    </div>
                  </Link>

                  <Link
                    className="link"
                    to={`/financial?events=${data?.id}&data_type=1`}
                  >
                    <div className="drop" onClick={showModal}>
                      <div className="first">
                        <div className="badge">
                          <img
                            className="ticket"
                            src={billet}
                            alt="badge"
                            width={20}
                            height={21}
                          />
                        </div>
                        <h2>Financeiro</h2>
                      </div>

                      <BiChevronRight
                        className="icon"
                        color={Colors.primary100}
                        size={28}
                      />
                    </div>
                  </Link>
                </FooterStyled>
              </TabPane>
              <TabPane tab="Ingressos" key="2">
                <ContainerCards>
                  <CardTicket>
                    <h2 className="created">INGRESSOS SALVOS</h2>

                    {dataTickets && dataTickets?.length > 0 ? (
                      <>
                        <ListTicketByEvent event_id={String(id) ?? ""} />

                        <MobileAddTicket
                          onClick={() => setIsOpenDrawerTicket(true)}
                        >
                          <BiPlus />
                          Adicionar novo ingresso
                        </MobileAddTicket>
                      </>
                    ) : (
                      <div className="container-card">
                        <EmptyTicketComponent
                          title="Você não cadastrou ingressos ainda :("
                          subtitle="Você não possui ingressos cadastrados, aproveite para cadastrar um agora!"
                        />

                        <div className="border">
                          <div className="line"></div>

                          <button onClick={showModal}>
                            <BiPlus size={18} color={Colors.primary100} />
                            <span>Cadastrar um ingresso</span>
                          </button>
                        </div>
                      </div>
                    )}

                    <ModalCreateAndEditTicket event_id={String(id)} />

                    <DrawerCreateAndEditTicket event_id={String(id)} />
                  </CardTicket>
                </ContainerCards>
              </TabPane>
            </Tabs>
          </ContainerTabs>
        </ContainerItems>

        <ModalCreateAndEditTicket event_id={String(id)} />
      </Container>

      <Footer />

      {visible && <Popup handleVisible={handleCookies} />}
    </>
  );
}
