import { Colors } from "constants/colors";
import { Modal } from "antd";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { EAdminStatus } from "types";
import { ADMIN_STATUS_COLORS } from "constants/adminStatus";

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
  }
`;

export const ContainerItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1280px;

  padding-right: 38px;
  padding-left: 38px;

  margin-top: 9.14rem;

  width: 100%;

  @media (max-width: 1024px) {
    margin-top: 40px;
    padding-left: 10px;
    padding-right: 10px;
  }

  h1 {
    font-weight: bold;
    font-size: 1.87rem;
    margin-bottom: 0px;
    color: ${Colors.black100};

    @media (max-width: 1024px) {
      margin-left: 20px;
    }

    @media (max-width: 800px) {
      font-weight: bold;
      font-size: 16px;
      line-height: 25px;

      color: ${Colors.black100};
      margin-left: 20px;
    }
  }

  div.bread {
    @media (max-width: 1024px) {
      display: none;
    }
  }
`;

export const HeaderEvent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 54px;

  div.buttons {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: 1024px) {
      display: none;
    }

    button {
      cursor: pointer;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      width: 12.81rem;
      height: 2.81rem;

      background: ${Colors.primary10};
      border-radius: 0.65rem;

      span {
        font-family: "Cairo";
        font-weight: bold;
        font-size: 0.93rem;
        margin-bottom: 0px;

        color: ${Colors.primary100};
        margin-left: 10px;
      }
    }

    button.second {
      margin-left: 12px;
    }
  }
`;

export const ContainerTabs = styled.div`
  margin-top: 1.5rem;

  .ant-tabs-tab {
    font-size: 1.03rem;
    font-family: "Poppins";
    font-weight: normal;
    margin-bottom: 0px;
    color: ${Colors.black70};
  }

  .ant-tabs-ink-bar {
    color: ${Colors.primary100};
    background: ${Colors.primary100};
  }

  .ant-tabs-tab-active {
    color: ${Colors.primary100} !important;
    font-weight: 700;
    font-size: 1.03rem;
    margin-bottom: 0px;
    color: ${Colors.primary100};
  }

  .ant-tabs-ink-bar {
    color: ${Colors.primary100};
    background: ${Colors.primary100};
  }

  .ant-tabs-tab-btn {
    font-family: "Poppins";
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-family: "Poppins";
    color: ${Colors.primary100} !important;
  }

  @media (max-width: 1230px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: 800px) {
    margin-top: 20px;

    .ant-tabs-tab {
      font-family: "Poppins";
      font-weight: normal;
      font-size: 10px;
      line-height: 160%;
      text-transform: uppercase;

      color: ${Colors.black70};
    }

    .ant-tabs-tab-active {
      color: ${Colors.primary100} !important;

      font-weight: bold;
      font-size: 10px;
      line-height: 160%;
      text-transform: uppercase;

      color: ${Colors.primary100};
    }

    .ant-tabs-ink-bar {
      color: ${Colors.primary100};
      background: ${Colors.primary100};
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: ${Colors.primary100} !important;
      font-weight: 500;
    }

    .ant-tabs-nav {
      width: 100% !important;
    }

    .ant-tabs-tab {
      display: block;
      flex: 1;
      text-align: center;
    }

    .ant-tabs-nav > div:nth-of-type(1) {
      display: unset !important;
      width: 100% !important;
    }

    .ant-tabs-tab-active {
      color: ${Colors.primary100} !important;
    }
    .ant-tabs-ink-bar {
      color: ${Colors.primary100};
      background: ${Colors.primary100};
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: ${Colors.primary100} !important;
      font-weight: 500;
    }
  }
`;

export const ContainerDetailEvent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* margin-top: 69px; */

  @media (max-width: 800px) {
    margin-top: 20px;
  }

  h2.resume {
    font-family: "Open Sans";
    font-weight: bold;
    font-size: 1.21rem;
    margin-bottom: 0px;
    margin-top: 3.23rem;

    color: ${Colors.black30};

    @media (max-width: 800px) {
      font-weight: bold;
      font-size: 10px;
      line-height: 14px;

      text-transform: uppercase;

      color: ${Colors.gray20};

      opacity: 0.75;
    }
  }

  div.container-img {
    margin-top: 26px;

    @media (max-width: 800px) {
      margin-top: 15px;
    }

    img {
      border-radius: 24px 24px 0px 0px;
      width: 100%;
      height: 30.03rem;
      object-fit: cover;

      @media (max-width: 800px) {
        height: 120px;
      }
    }
  }

  div.event-privacity {
    width: 100%;
    height: 3.28rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${Colors.black5};

    @media (max-width: 800px) {
      height: 27px;
    }

    img {
      width: 1.12rem;
      height: 1.21rem;

      @media (max-width: 800px) {
        width: 11px;
        height: 14;
      }
    }

    h3 {
      font-family: "Cairo";
      font-style: normal;
      font-weight: 700;
      font-size: 1.21rem;

      margin-bottom: 0px;
      margin-left: 0.79rem;

      display: flex;
      align-items: center;

      color: ${Colors.black50};

      @media (max-width: 800px) {
        font-size: 8px;
        margin-left: 7px;
        margin-top: 2px;
      }
    }
  }
`;

export const ContainerInformations = styled.div`
  padding: 2.34rem 2.81rem;

  border: 1px solid ${Colors.border40};
  border-radius: 0px 0px 0.93rem 0.93rem;

  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 800px) {
    padding: 10px 12px;
  }

  h2 {
    font-family: "Cairo";
    font-style: normal;
    font-weight: 700;
    font-size: 1.87rem;

    margin-bottom: 0px;

    color: ${Colors.black80};

    @media (max-width: 800px) {
      font-weight: bold;
      font-size: 14px;
      line-height: 26px;

      color: ${Colors.black100};
    }
  }

  div.publicate {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1.4rem;

    div.status {
      width: 0.93rem;
      height: 0.93rem;
      border-radius: 50%;
      background-color: ${Colors.success};

      @media (max-width: 800px) {
        width: 6px;
        height: 6px;
      }
    }

    h3 {
      font-family: "Open Sans";
      font-size: 1.21rem;
      font-weight: 600;

      color: ${Colors.black80};
      margin-left: 1.03rem;
      margin-bottom: 0px;

      @media (max-width: 800px) {
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;
        margin-left: 8px;
        color: ${Colors.black100};
      }
    }
  }

  div.local {
    margin-top: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;

    h4 {
      font-family: "Open Sans";
      font-size: 1.21rem;
      font-weight: normal;

      margin-left: 1.03rem;
      color: ${Colors.black70};
      margin-bottom: 0px;

      @media (max-width: 800px) {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        margin-left: 7px;
        color: ${Colors.black50};
      }
    }
  }

  div.data {
    margin-top: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 30px;
    border-bottom: 1px solid ${Colors.border40};

    @media (max-width: 800px) {
      padding-bottom: 22px;

      svg {
        width: 14px;
        height: 14px;
      }
    }

    h4 {
      font-family: "Open Sans";
      font-size: 1.21rem;
      font-weight: normal;

      margin-left: 15px;
      color: ${Colors.black70};
      margin-bottom: 0px;

      @media (max-width: 800px) {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        margin-left: 7px;
        color: ${Colors.black50};
      }
    }
  }

  div.link-event {
    @media (min-width: 800px) {
      display: none;
    }

    margin-top: 22px;
    margin-bottom: 8px;

    h4.link {
      font-weight: normal;
      font-size: 10px;
      line-height: 24px;

      display: flex;
      align-items: center;

      color: ${Colors.secondary40};
    }

    nav {
      width: 100%;
      height: 31px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 36px;

      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 10px;

      border-radius: 6px;
      border: 1px solid ${Colors.border};

      div.searchInput {
        width: 100%;
        height: 100%;

        input {
          height: 100%;
          width: 90%;
          border: none;
          outline: none;
          background-color: transparent;
          padding: 0px 2px 0px 28px;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;

          color: ${Colors.secondary70};
        }
      }

      div.search {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18%;
        height: 100%;

        div.copy {
          width: 42px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;

          background: ${Colors.primary100};

          box-shadow: 0px 3px 20px rgba(212, 0, 80, 0.15);
          border-radius: 3px;
        }
      }
    }
  }
`;

export const ContainerDescription = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.93rem;
  height: 100%;

  border: 1px solid ${Colors.border40};

  margin-top: 0.93rem;
  padding: 2.34rem 2.81rem;

  @media (max-width: 800px) {
    padding: 18px 27px;
    padding-bottom: 0px;
  }

  div.title-description {
    display: flex;
    flex-direction: column;

    h2 {
      font-weight: bold;
      font-size: 1.5rem;
      margin-bottom: 0px;
      color: ${Colors.black100};

      @media (max-width: 800px) {
        font-weight: bold;
        font-size: 14px;
        line-height: 26px;

        color: ${Colors.black100};
      }
    }

    span {
      font-weight: normal;
      font-size: 1.03rem;
      margin-bottom: 0px;

      color: ${Colors.black70};
      margin-top: 1.4rem;

      @media (max-width: 800px) {
        font-weight: normal;
        font-size: 12px;
        line-height: 160%;

        text-indent: 16px;

        color: ${Colors.black100};
        margin-top: 6px;
      }
    }
  }

  div.classification {
    margin-top: 2.34rem;

    padding-bottom: 2.57rem;

    border-bottom: 1px solid ${Colors.border40};

    @media (max-width: 800px) {
      margin-top: 18px;
      border: 0;
    }

    h4 {
      font-weight: bold;
      font-size: 1.21rem;
      margin-bottom: 0px;
      color: ${Colors.black100};

      @media (max-width: 800px) {
        font-weight: bold;
        font-size: 14px;
        line-height: 26px;

        color: ${Colors.black100};
      }
    }

    div.age {
      margin-top: 0.93rem;
      width: 637px;
      height: 104px;
      background: ${Colors.white};
      border: 1px solid ${Colors.border50};
      padding: 0.98rem 0.93rem;
      border-radius: 0.93rem;

      display: flex;
      align-items: center;

      @media (max-width: 800px) {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-left: 4px;
        border: 0;
        padding: 0;
      }

      span {
        font-weight: 600;
        font-size: 0.93rem;
        line-height: 160%;
        color: ${Colors.black70};

        margin-left: 20px;

        @media (max-width: 800px) {
          font-weight: normal;
          font-size: 12px;
          line-height: 17px;

          margin-left: 15px;

          color: ${Colors.black100};
        }
      }
    }
  }

  div.organizer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 55px;

    @media (max-width: 800px) {
      display: none;
    }

    h2.title-organizer {
      font-family: "Cairo";
      font-weight: 700;
      font-size: 1.5rem;
      margin-bottom: 0px;
      color: ${Colors.black100};
    }

    div.border {
      width: 100%;
      display: flex;
      flex-direction: column;

      min-height: 171px;
      background: ${Colors.white};
      margin-top: 30px;

      border: 1px solid ${Colors.border50};
      box-sizing: border-box;
      padding: 20px 60px 29px 30px;
      border-radius: 20px;

      div.header {
        display: flex;
        flex-direction: row;

        border-bottom: 1px solid ${Colors.border40};
        padding-bottom: 1.5rem;

        div.avatar {
          img {
            border-radius: 50%;
          }
        }

        div.content {
          margin-left: 20px;

          h4 {
            font-family: "Poppins";
            font-weight: 500;
            font-size: 1.21rem;
            margin-bottom: 0px;
            color: ${Colors.secondary100};
          }

          div.informations-content {
            display: flex;
            flex-direction: row;
            margin-top: 0.93rem;

            div.row {
              display: flex;
              flex-direction: row;
              align-items: center;

              div.square {
                width: 1.68rem;
                height: 1.68rem;
                display: flex;
                align-items: center;
                justify-content: center;

                background: ${Colors.primary10};
                border-radius: 0.56rem;
              }
            }

            div.row-left {
              display: flex;
              flex-direction: row;
              align-items: center;

              div.square {
                width: 1.68rem;
                height: 1.68rem;

                background: ${Colors.primary10};
                border-radius: 0.56rem;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              margin-left: 0.56rem;
            }

            h5 {
              font-family: "Poppins";
              font-weight: normal;
              font-size: 0.93rem;
              margin-bottom: 0px;
              color: ${Colors.primary100};
              text-decoration-line: underline;
              margin-left: 0.56rem;
            }

            h5 + h5 {
              margin-left: 12px;
            }
          }
        }
      }

      div.informations {
        margin-top: 25px;

        h5 {
          font-weight: bold;
          font-size: 1.17rem;
          margin-bottom: 0px;
          color: ${Colors.secondary100};
        }

        span {
          font-weight: normal;
          font-size: 1.03rem;
          margin-bottom: 0px;

          color: ${Colors.black70};

          margin-top: 0.93rem;
        }
      }
    }
  }
`;

export const ContainerAboutOrganizerMobile = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${Colors.white};
  border: 1px solid ${Colors.border20};
  box-sizing: border-box;
  border-radius: 14px;

  padding: 18px 22px;
  margin-top: 24px;

  @media (min-width: 800px) {
    display: none;
  }

  h3.organizer {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 0px;

    color: ${Colors.black100};
  }

  span.organizer {
    font-size: 12px;
    text-indent: 16px;
    color: ${Colors.black100};
    margin-top: 0.93rem;
    margin-bottom: 0px;
  }
`;

export const ContainerCards = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 800px) {
    margin-bottom: 2rem;
  }

  > div + div {
    margin-top: 20px;
  }
`;

export const CardTicket = styled.div`
  display: flex;
  flex-direction: column;

  padding: 2.81rem 1.87rem;

  background: ${Colors.white};
  border: 1px solid ${Colors.border20};

  border-radius: 0.93rem;

  @media (max-width: 800px) {
    padding: 0;
    border: 0;
  }

  div.container-card {
    @media (max-width: 800px) {
      margin-top: 10px;
    }

    div.border {
      margin-top: 3.21rem;

      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      div.line {
        padding-top: 2.81rem;
        border-top: 1px solid ${Colors.secondary10};
        width: 80%;

        @media (max-width: 600px) {
          width: 100%;
        }
      }

      button {
        cursor: pointer;
        border: 0;
        outline: 0;
        max-width: 22.17rem;
        width: 100%;
        height: 2.81rem;

        background: ${Colors.primary20};
        border-radius: 0.65rem;

        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-family: "Cairo";
          font-style: normal;
          font-weight: 700;
          font-size: 0.93rem;
          margin-left: 0.45rem;
          margin-bottom: 0px;

          color: ${Colors.primary100};
        }
      }
    }
  }

  h2.created {
    font-family: "Open Sans";
    font-weight: 700;
    font-size: 1.21rem;
    margin-bottom: 2.01rem;

    color: ${Colors.black30};

    @media (max-width: 800px) {
      font-weight: bold;
      font-size: 10px;
      text-transform: uppercase;
      color: ${Colors.gray20};
      opacity: 0.75;
      margin-bottom: 10px;
    }
  }
`;

interface CardAccordionProps {
  active?: boolean;
}

export const CardAccordion = styled.div<CardAccordionProps>`
  cursor: pointer;
  width: 100%;
  height: 5.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.84rem;

  background: ${(props) => (props.active ? Colors.black5 : Colors.white)};

  z-index: 2;

  border: 1px solid ${Colors.border50};
  box-shadow: 0px 4px 20px rgba(18, 25, 34, 0.05);
  border-radius: 0.93rem;

  padding: 0.89rem 1.92rem 1.17rem 1.96rem;

  @media (max-width: 800px) {
    height: 49px;
    padding: 7px 21px 3px 19px;
    margin-top: 0px;
    margin-bottom: 0px;
    border-radius: 5px;

    svg {
      width: 13px;
      height: 13px;
    }
  }

  div.content {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    div.image {
      margin-top: 2px;

      @media (max-width: 800px) {
        margin-top: 5px;
      }
    }

    div.texts {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-left: 23px;

      h2 {
        font-family: "Poppins";
        font-weight: 600;
        font-size: 1.03rem;

        margin-bottom: 0px;

        color: ${Colors.secondary100};

        @media (max-width: 800px) {
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;

          color: ${Colors.black100};
        }
      }

      p {
        font-family: "Cairo";
        font-weight: bold;
        font-size: 0.93rem;
        margin-bottom: 0px;

        color: ${Colors.secondary40};

        @media (max-width: 800px) {
          font-weight: bold;
          font-size: 10px;
          line-height: 19px;

          color: ${Colors.secondary40};
        }
      }
    }
  }
`;

export const ContainerBorderMobile = styled.div`
  width: 100%;
  min-height: 180px;
  display: flex;
  flex-direction: column;

  border: 1px solid ${Colors.border50};
  border-top: 0;
  box-sizing: border-box;
  border-radius: 0px 0px 5px 5px;
  margin-top: 15px;
  padding: 17px 15px;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const DataDropDown = styled.div`
  display: block;
  position: relative;

  width: 100%;

  z-index: 1;
  margin-top: -35px;

  ul {
    margin-top: 18px;

    h2 {
      color: ${Colors.secondary20};
      font-weight: bold;
      font-size: 16px;
      line-height: 160%;
      margin-left: 17px;
    }

    li {
      width: 100%;
      list-style: none;
      height: 43px;

      margin-top: 10px;
      border-bottom: 1px solid ${Colors.border10};
      display: flex;
      align-items: center;

      span {
        cursor: pointer;
        color: ${Colors.secondary100};
        font-weight: 600;
        font-size: 20px;
        line-height: 160%;
        margin-left: 17px;
      }
    }

    li.exit {
      border: 0;

      span {
        color: ${Colors.primary100};
      }
    }
  }
`;

export const ContainerBorder = styled.div`
  width: 100%;
  min-height: 5.25rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid ${Colors.border50};
  border-top: 0;
  box-sizing: border-box;
  border-radius: 20px;
  padding-right: 20px;
  padding-bottom: 0.98rem;

  @media (max-width: 800px) {
    display: none;
  }

  div.content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 70px;
    padding-left: 30px;
    width: 100%;

    div.column {
      display: flex;
      flex-direction: column;

      h2 {
        font-family: "Cairo";
        font-weight: bold;
        font-size: 0.93rem;
        line-height: 160%;
        color: ${Colors.black80};
      }

      p {
        font-family: "Poppins";
        font-weight: normal;
        font-size: 0.84rem;
        margin-bottom: 0px;
        color: ${Colors.black70};
      }
    }
  }

  div.container-image {
    margin-top: 40px;

    @media (max-width: 1200px) {
      padding-left: 10px;
    }

    img {
      border-radius: 10px;
    }
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;

  @media (max-width: 800px) {
    margin-top: 12px;
  }
`;

export const ButtonVisualizationTicket = styled.button`
  cursor: pointer;
  width: 262px;
  height: 60px;

  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (max-width: 800px) {
    width: 25.73px;
    height: 25.73px;
    border-radius: 50%;

    div.text {
      display: none;
    }

    svg {
      width: 13px;
      height: 13px;
    }
  }

  height: 60px;
  background: ${Colors.white20};
  border-radius: 14px;
  margin-right: 10px;

  span {
    font-weight: bold;
    font-size: 20px;
    line-height: 160%;
    margin-left: 10px;
    color: ${Colors.secondary100};
  }
`;

export const ButtonDownload = styled.button`
  width: 12.28rem;
  height: 2.81rem;

  cursor: pointer;
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background: ${Colors.white20};
  border-radius: 14px;
  margin-right: 10px;

  @media (max-width: 800px) {
    width: 25.73px;
    height: 25.73px;
    border-radius: 50%;

    div.text {
      display: none;
    }

    svg {
      width: 13px;
      height: 13px;
    }
  }

  span {
    font-family: "Cairo";
    font-weight: bold;
    font-size: 0.93rem;
    margin-left: 10px;
    margin-bottom: 0px;
    margin-left: 10px;
    color: ${Colors.green10};
  }
`;

export const ButtonPrint = styled.button`
  width: 12.28rem;
  height: 2.81rem;

  cursor: pointer;
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 14px;

  background: ${Colors.white20};

  @media (max-width: 800px) {
    width: 25.73px;
    height: 25.73px;
    border-radius: 50%;

    div.text {
      display: none;
    }

    svg {
      width: 13px;
      height: 13px;
    }
  }

  span {
    font-family: "Cairo";
    font-weight: bold;
    font-size: 0.93rem;
    margin-left: 10px;
    margin-bottom: 0px;
    margin-left: 10px;
    color: ${Colors.red10};
  }
`;

export const ContainerModal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 94px 109px 64px 109px;

  @media (max-width: 800px) {
    padding: 0px;
  }

  div.logo-qr-code {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.content {
    margin-top: 51px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      font-weight: bold;
      font-size: 32px;
      line-height: 160%;

      color: ${Colors.secondary100};
    }

    p {
      font-weight: normal;
      font-size: 20px;
      line-height: 160%;
      margin-top: 14px;

      text-align: center;

      color: ${Colors.black90} #292c42;

      strong {
        font-weight: bold;
        font-size: 20px;
        line-height: 160%;

        text-align: center;

        color: ${Colors.black90} #292c42;
      }
    }
  }

  div.or {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 35px;

    @media (max-width: 800px) {
      margin-top: 25px;
    }

    span {
      padding: 0px 11px;
    }

    div.line {
      min-width: 160px;
      width: 100%;
      border: 1px solid ${Colors.secondary100};
      opacity: 0.2;

      @media (max-width: 800px) {
        min-width: 40%;
      }
    }
  }

  div.download-app {
    width: 100%;
    margin-top: 36px;
    align-items: center;
    justify-content: center;

    padding-left: 10px;
    padding-right: 10px;

    h5 {
      font-weight: normal;
      font-size: 20px;
      line-height: 160%;
      text-align: center;
      color: ${Colors.black90};
    }

    div.row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        cursor: pointer;
        min-width: 189px;
        min-height: 128px;

        @media (max-width: 800px) {
          min-width: 129px;
          min-height: 78px;
        }
      }
    }
  }
`;

export const RowMobileFirst = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15px;

  h6.row {
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    margin-bottom: 0px;
    color: ${Colors.black50};
  }

  span.row {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;

    color: ${Colors.black50};
    margin-left: 12px;
  }
`;

export const RowMobile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;

  h6.row {
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    margin-bottom: 0px;
    color: ${Colors.black50};
  }

  span.row {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;

    color: ${Colors.black50};
    margin-left: 12px;
  }
`;

export const RowMobileBorder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;

  padding-bottom: 12px;

  border-bottom: 1px solid ${Colors.secondary10};

  h6.row {
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    margin-bottom: 0px;
    color: ${Colors.black50};
  }

  span.row {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;

    color: ${Colors.black50};
    margin-left: 12px;
  }
`;

export const FooterStyled = styled.footer`
  display: flex;
  flex-direction: column;
  margin-top: 3.75rem;
  justify-content: flex-end;
  padding-top: 3.75rem;
  border-top: 1px solid ${Colors.secondary10};
  margin-bottom: 2rem;

  div + div {
    margin-top: 0.93rem;

    @media (max-width: 800px) {
      margin-top: 10px;
    }
  }

  h1 {
    font-family: "Open Sans";
    text-align: left;
    font-weight: bold;
    font-size: 1.21rem;

    margin-left: 4px;
    color: ${Colors.black30};
    margin-bottom: 1.4rem;

    @media (max-width: 800px) {
      font-weight: bold;
      font-size: 10px;
      line-height: 14px;
      text-transform: uppercase;

      color: ${Colors.gray20};
      margin-bottom: 10px;
      opacity: 0.75;
    }
  }

  .link {
    margin-top: 0.93rem;
  }

  div.drop {
    cursor: pointer;
    width: 100%;
    height: 5.62rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-left: 1.4rem;
    padding-right: 1.82rem;

    @media (max-width: 800px) {
      height: 38px;

      padding-left: 19px;
      padding-right: 13px;

      svg.icon {
        height: 18px;
        width: 18px;
      }
      border-radius: 8px;
    }

    background: ${Colors.white};
    border: 1px solid ${Colors.black10};
    box-sizing: border-box;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
    border-radius: 20px;

    div.first {
      display: flex;
      flex-direction: row;
      align-items: center;

      div.badge {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.81rem;
        height: 2.81rem;

        background: ${Colors.primary10};
        border-radius: 0.93rem;

        @media (max-width: 800px) {
          width: 15px;
          height: 13px;
          background: ${Colors.white};
        }
      }

      h2 {
        font-family: "Poppins";
        color: ${Colors.primary100};
        font-weight: 400;
        font-size: 1.21rem;
        margin-left: 0.93rem;
        margin-bottom: 0px;

        @media (max-width: 800px) {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
`;

export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
`;

export const ParentalRatingContainer = styled.div`
  /* width: 100%; */
  display: flex;
  align-items: center;
  padding: 1rem;
  text-align: left;

  border-radius: 1rem;
  border: 1px solid ${Colors.black10};
  margin-top: 0.93rem;

  p.normal {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 0.93rem;
    margin-bottom: 0px;

    color: ${Colors.black70};
  }
`;

interface IParentalRatingProps {
  rating: any;
}

export const ParentalRatingIcon = styled.div<IParentalRatingProps>`
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  margin-right: 1rem;
  flex-shrink: 0;
  align-items: center;
  background: ${(props) => props.rating?.color};
  background: linear-gradient(
    0deg,
    ${(props) => props.rating?.color} 00%,
    rgba(255, 255, 255, 1) 200%
  );

  p.inside {
    font: normal 22px Poppins, "Poppins", sans-serif;
    color: ${Colors.white};
    padding-top: 1.2rem;
  }
`;

export const MobileEditButton = styled(Link)`
  @media (min-width: 1024px) {
    display: none;
  }

  margin-top: 40px;
  padding: 8px 0;
  width: 100%;

  border: none;
  outline: none;
  border-radius: 8px;
  background: #fbe5ed;
  cursor: pointer;

  width: 100%;
  text-align: center;

  color: ${({ theme }) => theme.colors.pink};
  font-family: "Cairo";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const MobileAddTicket = styled.button`
  margin-top: 30px;
  padding: 8px 0;
  width: 100%;

  border: none;
  outline: none;
  border-radius: 8px;
  background: ${Colors.primary10};
  cursor: pointer;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;

  color: ${({ theme }) => theme.colors.pink};
  font-family: "Cairo";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const ContainerLink = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.68rem;

  h2 {
    color: ${Colors.secondary40};
    font-family: Poppins;
    font-size: 0.93rem;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0px;

    @media (max-width: 670px) {
      font-size: 11px;
    }
  }

  span.error {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.error20};
    position: relative;
  }
`;

export const RowShared = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 4px;
  gap: 0.93rem;

  input.upload {
    border: none;
    outline: 0;
    width: 100%;
    height: 2.81rem;
    border-radius: 0.37rem;
    border: 2px solid ${Colors.secondary20};
    background: ${Colors.white};
    padding-left: 0.93rem;

    @media (max-width: 670px) {
      border: transparent;
    }
  }

  @media (max-width: 670px) {
    border-radius: 3px;
    padding-right: 12px;
    border-radius: 5px;
    border: 1.5px solid ${Colors.border20};
    background: ${Colors.white};
  }
`;

export const ButtonShare = styled.div`
  cursor: pointer;
  border: 0;
  outline: 0;
  display: flex;
  width: 4.68rem;
  height: 2.81rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.65rem;
  background: ${Colors.primary100};
  box-shadow: 0px 15px 30px 0px rgba(212, 0, 80, 0.15);

  img {
    width: 1.12rem;
    height: 1.12rem;

    @media (max-width: 670px) {
      width: 14px;
      height: 14px;
    }
  }

  @media (max-width: 670px) {
    border-radius: 3px;
    width: 48px;
    height: 28px;
  }
`;

export const RowCardLocalization = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
`;

export const TitleLocalization = styled.h4`
  color: ${Colors.secondary100};
  font-family: Poppins;
  font-size: 1.03rem;
  font-style: normal;
  font-weight: 500;
  margin-left: 0.56rem;
  margin-bottom: 0px;
`;

export const StatusComponent = styled.div<{ status: EAdminStatus }>`
  width: 4.96rem;
  height: 1.68rem;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ status }) =>
    ADMIN_STATUS_COLORS.find((a) => a.value === status?.toLowerCase())?.color};
  border-radius: 4.68rem;

  span {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 0.7rem;
    margin-bottom: 0px;
    text-align: center;

    color: ${Colors.white};
  }
`;

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  > h4 {
    margin-top: 4px;
  }
`;
